<template>
  <div class="about">
    <b-card>
      <b-card-text class="text-center"><h2>Pipe Law</h2></b-card-text>
      <b-card-text>
        <h5>Visão Geral:</h5>
        <p> O sistema "Pipe Law" é o resultado de pesquisa, desenvolvimento e inovação.
          Nosso compromisso é fornecer uma plataforma de excelência, projetada especificamente
          para simplificar e otimizar o processo de pesquisa e aquisição de processos legais
        </p>
        <p>Um software da PipeDevelopment. © 2023 Todos os direitos reservados.</p>
      </b-card-text>
    </b-card>
<!--    <b-card>
      <b-card-text><h4 class="mb-2">Logs das versões</h4></b-card-text>
      <b-card-text>
        <h5>1.0.15
          <b-badge variant="success">atual</b-badge>
        </h5>
        <p>Adicionado graficos na tela Home
          <br/>
        Track de revisões de processos</p>
      </b-card-text>
      <b-card-text>
        <h5>1.0.14</h5>
        <p>Adicionado filtros de fontes e índices</p>
      </b-card-text>
      <b-card-text>
        <h5>1.0.13</h5>
        <p>Adicionado a opção de salvar e carregar filtros</p>
        <div>
          <b-img thumbnail fluid v-for="(img, index) in imgs['1.0.13']" class="cursor-pointer"
                 v-bind:key="`[1.0.13]-${index}`" :src="img.src" :title="img.title"
                 @click="()=>{$refs.imageModal.showModal(imgs['1.0.13'], index)}"
          />
        </div>
      </b-card-text>
      <b-card-text>
        <h5>1.0.12</h5>
        <p>Sistema de gerenciamento de usuarios</p>
      </b-card-text>
      <b-card-text>
        <h5>1.0.11</h5>
        <p>Relação criada/fortificada entre o processo atual e o processo principal, em caso de Cumprimento de
          sentença</p>
        <div>
          <b-img thumbnail fluid v-for="(img, index) in imgs['1.0.11']" class="cursor-pointer"
                 v-bind:key="`[1.0.11]-${index}`" :src="img.src" :title="img.title"
                 @click="()=>{$refs.imageModal.showModal(imgs['1.0.11'], index)}"
          />
        </div>
      </b-card-text>
    </b-card>
    <image-modal ref="imageModal"/>-->
  </div>
</template>

<script>
import ImageModal from '@/components/modal/imageModal'
export default {
  name: 'About',
  components: { ImageModal },
  data: () => {
    return {
      imgs:{
        '1.0.11': [
          {src: require('@/assets/images/logs/cumprimento-de-sentenca.png'), title: 'cumprimento de sentenca na busca'},
          {src: require('@/assets/images/logs/cumprimento-de-sentenca-filtro.png'), title: 'cumprimento de sentenca no filtro'},
          {src: require('@/assets/images/logs/processo-principal-details.png'), title: 'detalhes do processo-principal no modal do processo'},
        ],
        '1.0.13': [
          {src: require('@/assets/images/logs/suit-save-filter.png'), title: 'cumprimento de sentenca na busca'},
          {src: require('@/assets/images/logs/suit-load-filter.png'), title: 'cumprimento de sentenca no filtro'},
        ]
      }
    }
  },
}
</script>

<style lang="scss">
.about {
  .img-thumbnail {
    max-height: 100px;
    margin-right: 25px;
  }
}
</style>
