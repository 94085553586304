export default {
  data:()=>({
    refreshKey: 0,
  }),
  created: function () {

  },
  computed:{
    _baseKey(){
      return this.refreshKey;
    },
    queryParams(){
      return this.$route.query
    }
  },
  methods: {
    refreshTables(){
      this.$root.$emit('system::refresh-tables')
    },
    forceUpdate() {
      this.refreshKey++
    },
    pushState(data) {
      const url = new URL(window.location.href);
      Object.keys(data).forEach(key => {
        if(data[key] == null){
            url.searchParams.delete(key);
        }else {
          url.searchParams.set(key, data[key])
        }
      });
      url.searchParams.sort()
      console.log(url.searchParams)
      window.history.pushState( null , null, url );
    },
    getErrorDescription(error) {
      if (error == null) {
        return 'Unknown error'
      }
      if (error.errorId == null) {
        return error.error !== null ? error.error : 'Unknown error'
      }
      if (error.params == null) {
        return this.$t(`errorDefinition.error.${error.errorId}`)
      } else {
        let $this = this;
        let params = error.params.reduce((acc, val, index) => Object.assign(acc, { [`param-${index}`]: $this.$t(`errorDefinition.params.${val.toLowerCase()}`) }), {})
        return this.$t(`errorDefinition.error.${error.errorId}`, params)
      }
    }
  }
}
