<template>
  <b-modal no-fade v-model="dialog.show" size="xl" :ref="`modal-images-${uniqueId}`" :id="`modal-images-${uniqueId}`"
           :hide-footer="true" centered @close="onDialogClose"
           no-close-on-backdrop
  >
    <b-carousel
        class="modal-images-carousel"
        :interval="0"
        v-model="currentIndex"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
          v-for="(image, index) in images" v-bind:key="index"
          style="min-height: 500px; max-height: 700px"
          v-bind:text="image.title"
          v-bind:img-src="image.src"
      ></b-carousel-slide>
    </b-carousel>
  </b-modal>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase'

export default {
  name: 'imageModal',
  mixins: [mixinBase],
  data: () => ({
    dialog: {
      show: false,
    },
    uniqueId: 0,
    images: [],
    currentIndex: 0,
  }),
  watch: {},
  created() {
    this.uniqueId = this._uid
  },
  methods: {
    showModal(imgs, index) {
      this.loading = true
      this.images = imgs
      this.currentIndex = index || 0
      this.dialog.show = true
    },
    closeModal() {
      this.dialog.show = false
      this.images = []
      this.currentIndex = 0
    },
    onDialogClose() {
      this.closeModal()
    },
  },
}
</script>

<style lang="scss">
.modal-images-carousel {

  .carousel-caption {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 30px;
    height: 30px;
    background-size: 100%, 100%;
    border-radius: 50%;
    border: 1px solid #000;
    background-color: #000;
  }


  .carousel-indicators li {
    color: #909090;
  }

  .carousel-item img{
    max-height: 700px
  }
}

</style>
